import React from 'react';
import { AppRouter } from './router';
import { Helmet } from 'react-helmet';
import './App.css';

const App = () => {
  window.location.replace('https://cnftjungle.io/sniping');

  return null;
  return (
    <div className="App">
      <Helmet>
        <title> CNFT Predator</title>
        <meta
          name="description"
          content="CNFT Predator - Stay ahead of the pack with our NFT Extension"
        />
        <noscript>CNFT Predator</noscript>
      </Helmet>
      <AppRouter />
    </div>
  );
};

export default App;
