import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { withStyles } from '@mui/styles';
import extension from '../assets/extension.png';
import developer from '../assets/developer.png';
import unpacked from '../assets/unpacked.png';
import load from '../assets/load.png';
import bar from '../assets/bar.png';
import { Link, StepContent, Typography } from '@mui/material';
import useWindowDimensions from '../hooks/useWindowDimensions';

const styles = (theme) => ({
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0,
    },
    '& span': {
      color: 'whitesmoke !important',
    },
  },
  step: {
    '& $completed': {
      color: 'lightgreen',
    },
    '& $text': {
      color: 'lightgreen',
    },
    '& $active': {
      color: 'pink',
    },
    '& $disabled': {
      color: 'red',
    },
  },
  root: {},
  alternativeLabel: {},
  active: {
    color: '#9D1C97 !important',
    '& text': {
      fill: 'whitesmoke',
    },
  }, //needed so that the &$active tag works
  completed: {},
  disabled: {},
});

const steps = {
  license: [
    {
      label: (
        <Typography>
          For pricing please refer to the
          <Link
            color="#B420A5"
            href="https://belowfloor.io/pricing"
            sx={{ fontWeight: 'bold' }}
          >
            {' '}
            Pricing
          </Link>
        </Typography>
      ),
    },
    {
      label: 'You will receive your one-time Access Key',
    },
    {
      label: 'Enter that into extension login tab to access the extension',
    },
  ],
  install: [
    {
      label: (
        <Typography sx={{ my: 1, fontWeight: 'bold' }}>
          Download from the{' '}
          <Link
            color="#B420A5"
            href="https://chrome.google.com/webstore/detail/cnft-predator/jlajkhhjgghiidlfgpkhhjbgbfkmklio"
          >
            Chrome Store
          </Link>
        </Typography>
      ),
    },
    {
      label:
        "Use your obtained access key to login to extension features. If you don't have a key refer to 'How to purchase an extension access key' above",
    },
  ],
  hunt: [
    {
      label: 'Select CNFT collection from the dropdown',
    },
    {
      label: 'Input below which price you are looking to snipe the asset',
    },
    {
      label: "Click 'Start Hunt' to queue the background search",
    },
  ],
  running: [
    {
      label: 'Open Running Hunts tab',
    },
    {
      label: "You'll see your saved and running hunts",
    },
    {
      label:
        'You can click dropdown button to see all found listings for that search',
    },
  ],
  rarity: [
    {
      label: 'Select CNFT collection from the dropdown',
    },
    {
      label: 'Input the asset number to check rarity for',
    },
    {
      label: "Click 'Check Rarity' to get asset information",
    },
  ],
  settings: [
    {
      label: 'Open Settings tab',
    },
    {
      label: 'There you can select different search settings options',
    },
    {
      label:
        'Open in new tab - will open a new tab when asset is found for your search params',
    },
    {
      label:
        'Send notification - will send a browser notification on asset found',
    },
    {
      label: 'Stop search - will stop the search once the asset has been found',
    },
    {
      label:
        'Continue search - will continue the search even if the asset has been found',
    },
    {
      label:
        'Select marketplaces - will filter only search results from selected marketplaces',
    },
  ],
};

const Instructions = ({ step, classes }) => {
  const { isPhone } = useWindowDimensions();

  return (
    <Box sx={{ ml: isPhone ? 0 : 5, maxWidth: 600, color: 'whitesmoke' }}>
      <Stepper
        orientation="vertical"
        classes={{
          root: classes.root,
        }}
      >
        {steps[step].map((step, index) => (
          <Step key={step.label} active={true}>
            <StepLabel
              sx={{ color: 'whitesmoke' }}
              classes={{
                alternativeLabel: classes.alternativeLabel,
                labelContainer: classes.labelContainer,
              }}
              StepIconProps={{
                classes: {
                  root: classes.step,
                  completed: classes.completed,
                  active: classes.active,
                  disabled: classes.disabled,
                },
              }}
            >
              {step.label}
            </StepLabel>
            {step.picture && (
              <StepContent sx={{ display: 'flex', justifyContent: 'left' }}>
                <img
                  src={step.picture}
                  alt="intruction"
                  style={{ borderRadius: 5 }}
                />
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default withStyles(styles)(Instructions);
