import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropUp';
import Instructions from '../components/Instructions';
import ClickToCopy from '../components/ClickToCopy';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { Link } from '@mui/material';

const intructions = [
  {
    position: 1,
    label: 'How to purchase an extension access key',
    description: (
      <>
        <Typography sx={{ my: 1, fontWeight: 'bold', fontSize: 20 }}>
          Download from the{' '}
          <Link
            color="#B420A5"
            href="https://chrome.google.com/webstore/detail/cnft-predator/jlajkhhjgghiidlfgpkhhjbgbfkmklio"
          >
            Chrome Store
          </Link>
        </Typography>
      </>
    ),
    id: 'license',
  },
  {
    position: 2,
    label: 'How to install the extension',
    description: '',
    id: 'install',
  },
  {
    position: 3,
    label: 'How to run an NFT Hunt',
    description: '',
    id: 'hunt',
  },
  {
    position: 4,
    label: 'How to check Running Hunts',
    description: '',
    id: 'running',
  },
  {
    position: 5,
    label: 'How to do a Rarity Check',
    description: '',
    id: 'rarity',
  },
  {
    position: 6,
    label: 'How to change Settings',
    description: '',
    id: 'settings',
  },
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: 'transparent',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowDropDownIcon sx={{ fontSize: '2rem', color: '#B420A5' }} />
    }
    {...props}
  />
))(({ theme }) => ({
  background: 'transparent',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Extension = () => {
  const [expanded, setExpanded] = useState('license');
  const { isPhone } = useWindowDimensions();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (isPhone) {
    return (
      <>
        <Container
          disableGutters
          maxWidth="md"
          component="main"
          sx={{
            pt: 5,
            textAlign: 'center',
            flex: 1,
            px: 5,
            pb: 2,
          }}
        >
          <Typography
            component="h1"
            variant="h2"
            color="inherit"
            sx={{ fontFamily: 'Russo One', color: '#B420A5', marginTop: 1 }}
          >
            NFT Extension
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            sx={{
              fontFamily: 'Montserrat',
              color: 'whitesmoke',
              fontWeight: 'bold',
              marginBottom: 1,
              marginTop: 3,
            }}
          >
            Guide
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <div
              style={{
                width: '60px',
                height: '0px',
                border: '1px solid rgba(180, 32, 165, 0.6)',
              }}
            />
            <div
              style={{
                width: '10px',
                height: '10px',
                background: 'rgba(180, 32, 165, 0.6)',
                borderRadius: '50px',
                marginRight: 7.5,
                marginLeft: 7.5,
              }}
            />
            <div
              style={{
                width: '60px',
                height: '0px',
                border: '1px solid rgba(180, 32, 165, 0.6)',
              }}
            />
          </div>
        </Container>
        <Container maxWidth="sm">
          <Grid container alignItems="center">
            <Typography
              component="p"
              variant="h7"
              color="inherit"
              sx={{
                fontFamily: 'Montserrat',
                color: 'whitesmoke',
                fontWeight: 'bold',
                overflowWrap: 'break-word',
                textAlign: 'center',
                pb: 3,
              }}
            >
              Please open the page on a desktop, as the installations steps are
              for chrome extension which is not yet available on the mobile.
              Perhaps a mobile app will be available down the roadmap depending
              on community votes.
            </Typography>
          </Grid>
        </Container>
      </>
    );
  }

  return (
    <Container
      disableGutters
      maxWidth="md"
      component="main"
      sx={{ pt: 5, textAlign: 'center', flex: 1, px: 5, minHeight: '986px' }}
    >
      <Typography
        component="h1"
        variant="h2"
        color="inherit"
        sx={{ fontFamily: 'Russo One', color: '#B420A5', marginTop: 1 }}
      >
        NFT Extension
      </Typography>
      <Typography
        component="h2"
        variant="h5"
        color="inherit"
        noWrap
        sx={{
          fontFamily: 'Montserrat',
          color: 'whitesmoke',
          fontWeight: 'bold',
          marginBottom: 1,
          marginTop: 5,
        }}
      >
        Guide
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div
          style={{
            width: '60px',
            height: '0px',
            border: '1px solid rgba(180, 32, 165, 0.6)',
          }}
        />
        <div
          style={{
            width: '10px',
            height: '10px',
            background: 'rgba(180, 32, 165, 0.6)',
            borderRadius: '50px',
            marginRight: 7.5,
            marginLeft: 7.5,
          }}
        />
        <div
          style={{
            width: '60px',
            height: '0px',
            border: '1px solid rgba(180, 32, 165, 0.6)',
          }}
        />
      </div>
      {intructions.map((instruction) => (
        <Accordion
          expanded={expanded === instruction.id}
          onChange={handleChange(instruction.id)}
        >
          <AccordionSummary
            aria-controls={`${instruction.id}-content`}
            id={`${instruction.id}d-header`}
          >
            <div
              style={{
                width: 30,
                height: 30,
                background:
                  'linear-gradient(187.83deg, #9D1C97 6.04%, rgba(157, 28, 151, 0) 160.71%)',
                transform: 'rotate(45deg)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 7.5,
              }}
            >
              <Typography
                sx={{
                  transform: 'rotate(-45deg)',
                  fontFamily: 'Mostserrat',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 16,
                  color: 'whitesmoke',
                }}
              >
                {instruction.position}
              </Typography>
            </div>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                color: 'whitesmoke',
                fontWeight: 'bold',
                fontSize: 18,
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, 0%)',
              }}
            >
              {instruction.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ textAlign: 'center' }}>
            {instruction.description && (
              <Typography sx={{ color: 'whitesmoke' }}>
                {instruction.description}
              </Typography>
            )}
            <div
              style={{
                display: 'flex',
                placeContent: 'left',
              }}
            >
              <Instructions step={instruction.id} />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default Extension;
