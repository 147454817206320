import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';
import claw from '../assets/claw.png';
import Box from '@mui/material/Box';
import history from '../config/history';
import useWindowDimensions from '../hooks/useWindowDimensions';

function Header(props) {
  const { isPhone } = useWindowDimensions();

  if (isPhone) {
    return (
      <React.Fragment>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginTop: 5,
          }}
        >
          <Button
            size="small"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 50,
            }}
            onClick={() => history.push('/')}
          >
            <img src={claw} alt="logo" width="75" />
          </Button>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IconButton
              sx={{
                '&:hover': {
                  color: '#B420A5',
                  background: '#B420A5',
                },
              }}
              onClick={() => {
                window.open('https://twitter.com/CnftPredator');
              }}
            >
              <Icon
                icon="akar-icons:twitter-fill"
                style={{ fontSize: 20, color: 'whitesmoke' }}
              />
            </IconButton>
            <IconButton
              sx={{
                '&:hover': {
                  color: '#B420A5',
                  background: '#B420A5',
                },
              }}
              onClick={() => {
                window.open('https://discord.gg/T9Ktk9j5vN');
              }}
            >
              <Icon
                icon="simple-icons:discord"
                style={{
                  fontSize: 20,
                  color: 'whitesmoke',
                  mr: 5,
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              variant="none"
              size="small"
              sx={{
                height: '30px',
                borderRadius: '20.5px',
                color: 'whitesmoke',
                fontWeight: 'bold',
                '&:hover': {
                  color: '#B420A5',
                },
              }}
              onClick={() => history.push('/team')}
            >
              Team
            </Button>
            <Button
              variant="none"
              size="small"
              sx={{
                height: '30px',
                borderRadius: '20.5px',
                color: 'whitesmoke',
                fontWeight: 'bold',
                '&:hover': {
                  color: '#B420A5',
                },
              }}
              onClick={() => history.push('/features')}
            >
              Features
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                // background: '#B420A5',
                // width: '150px',
                height: '30px',
                borderRadius: '20.5px',
                color: 'whitesmoke',
                border: 'none',
                '&:hover': {
                  background: '#160240',
                  color: '#B420A5',
                  border: 'none',
                },
              }}
              onClick={() => history.push('/extension')}
            >
              CNFT EXTENSION
            </Button>
          </Box>
        </Toolbar>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '70%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Button
          size="small"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 50,
          }}
          onClick={() => history.push('/')}
        >
          <img src={claw} alt="logo" width="75" />
        </Button>
        <Box>
          <IconButton
            sx={{
              '&:hover': {
                color: '#B420A5',
                background: '#B420A5',
              },
            }}
            onClick={() => {
              window.open('https://twitter.com/CnftPredator');
            }}
          >
            <Icon
              icon="akar-icons:twitter-fill"
              style={{ fontSize: 25, color: 'whitesmoke' }}
            />
          </IconButton>
          <IconButton
            sx={{
              '&:hover': {
                color: '#B420A5',
                background: '#B420A5',
              },
            }}
            onClick={() => {
              window.open('https://discord.gg/T9Ktk9j5vN');
            }}
          >
            <Icon
              icon="simple-icons:discord"
              style={{
                fontSize: 25,
                color: 'whitesmoke',
                mr: 5,
              }}
            />
          </IconButton>
          <Button
            variant="none"
            size="small"
            sx={{
              height: '30px',
              borderRadius: '20.5px',
              color: 'whitesmoke',
              fontWeight: 'bold',
              '&:hover': {
                color: '#B420A5',
              },
              ml: 1,
            }}
            onClick={() => history.push('/team')}
          >
            Team
          </Button>
          <Button
            variant="none"
            size="small"
            sx={{
              height: '30px',
              borderRadius: '20.5px',
              color: 'whitesmoke',
              fontWeight: 'bold',
              '&:hover': {
                color: '#B420A5',
              },
              mr: 1,
            }}
            onClick={() => history.push('/features')}
          >
            Features
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              background: '#B420A5',
              width: '150px',
              height: '30px',
              borderRadius: '20.5px',
              color: 'whitesmoke',
              border: 'none',
              '&:hover': {
                background: '#160240',
                color: '#B420A5',
                border: 'none',
              },
            }}
            onClick={() => history.push('/extension')}
          >
            CNFT EXTENSION
          </Button>
        </Box>
      </Toolbar>
    </React.Fragment>
  );
}

export default Header;
